import React, { useState, useEffect, useRef } from "react";
import { TokenData } from "../interfaces/TokenData";
import photon from '../assets/img/photon.jpg';
import moon from '../assets/img/moon.png';
import twitter from '../assets/img/x.jpg';
import { getTokenData } from '../services/tokenDataService';

interface TemplateProps {
    children: React.ReactNode;
}

const Layout: React.FC<TemplateProps> = ({ children }) => {
    const [tokenData, setTokenData] = useState<TokenData>();
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setTokenData(getTokenData())
        }
        fetchData();
        return () => { }
    }, []);

    if (!tokenData) return (<div>'Loading...'</div>)

    return (
        <div>
            <div className="container" style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div>
                    </div>
                </div>

                {children}


                <div className="footer" style={{ fontSize: "8pt" }}>
                    <div>
                        {
                            tokenData?.photonUrl != null && tokenData?.photonUrl.length > 0 ?                            
                            <a href={"" + tokenData?.photonUrl} target="_blank"><img className="social-icon" src={photon}></img></a>: <></>
                        }
                        {
                            tokenData?.dexUrl != null && tokenData?.dexUrl.length > 0 ?                            
                            <a href={"" + tokenData?.dexUrl} target="_blank"><img className="social-icon" src={moon}></img></a>: <></>
                        }
                        <a href={tokenData.twitterUrl} target="_blank"><img className="social-icon" src={twitter}></img></a>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default Layout;
