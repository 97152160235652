export const getTokenData = () => {
    return {
        ticker: "KH47",
        contractAddress: "",
        twitterUrl: "https://x.com/kh47sol",
        websiteUrl: "https://kh47.xyz",
        telegramUrl: "",
        photonUrl: "https://photon-sol.tinyastro.io/en/lp/HqSTy5zwwng3svbnzEJishRAKcjm5y4b1zMXTxPp8dt6?handle=26763194b0cba28033f12",
        dexUrl: "https://dexscreener.com/solana/4pyxp1r98mncdj5onrvc2tlufzxkelhto3fa1znvlp2t",
        pumpUrl: "",
    }
} 