import React, { useState, useEffect, useRef } from "react";
import Linky from "../components/Linky";
import Layout from "../components/Layout";
import { TokenData } from "../interfaces/TokenData";
import img1 from '../assets/img/img1.jpg';
import { getTokenData } from '../services/tokenDataService';

const Home: React.FC = () => {
    const [tokenData, setTokenData] = useState<TokenData>();
    const [clicks, setClicks] = useState<number>(0);
    const [showGame, setShowGame] = useState<boolean>(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    // Load pinned items from local storage
    useEffect(() => {
        setTokenData(getTokenData());
        const playAudio = async () => {
            try {
                setInterval(async () => {
                    if (audioRef.current) {
                        try {
                            audioRef.current.volume = 0.2;
                            await audioRef.current.play();
                            setIsPlaying(true);
                        } catch (error) {
                            console.log('Autoplay prevented:', error);
                        }
                    }
                }, 1000);
            } catch (error) {
                console.log('Autoplay prevented:', error);
            }
        };

        // Call the function to attempt playback
        playAudio();
        return () => {
        }
    }, []);

    const click = () => {        
        let next = clicks + 1
        setClicks(next);
        console.log(next);
        if(next == 5)
        {
            setShowGame(true);
        }
    }

    if (!tokenData) return <div>loading</div>

    const game = () => {
        return (
            <iframe style={{ display: showGame ? "inline" : "none" }} id="game_frame" width="800" height="600" src="https://shoot-orange-guy.pages.dev/"></iframe>
        )
    }

    return (
        <Layout>            
            <h1>KAMALA HARRIS</h1>
            <h1>The 47th President of the United States of America</h1>
            <h4><a style={{ color: "white", fontWeight: "bold" }} href={"" + tokenData?.photonUrl}>Buy ${tokenData.ticker} on Solana</a></h4>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap", minHeight: "500px" }}>
                
            <span style={{ display: showGame ? "none" : "inline", 
                
                position: "absolute", margin: "auto", fontSize: "24px" }}>Click {47 - clicks} times to make Kamala Harris the 
                <br/>47th President of the United States of America.</span>
                <img style={{ display: showGame ? "none" : "inline" }} onClick={() => click()} src={img1}></img>                
                {game()}
            </div>
            <audio ref={audioRef}  src="./stars.ogg" />
        </Layout >
    );
};

export default Home;
